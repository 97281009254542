import { render, staticRenderFns } from "./i18n-language-type-list.vue?vue&type=template&id=489b7665&scoped=true&"
import script from "./i18n-language-type-list.vue?vue&type=script&lang=ts&"
export * from "./i18n-language-type-list.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "489b7665",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\123\\river-web\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('489b7665')) {
      api.createRecord('489b7665', component.options)
    } else {
      api.reload('489b7665', component.options)
    }
    module.hot.accept("./i18n-language-type-list.vue?vue&type=template&id=489b7665&scoped=true&", function () {
      api.rerender('489b7665', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/system/i18n-language-type-list.vue"
export default component.exports