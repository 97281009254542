










































import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator';
import { languageTypeAdd, languageTypeUpdate } from '@/api/system/LanguageApi';

@Component({
  name: 'I18nLanguageTypeForm',
  components: {}
})
export default class I18nLanguageTypeForm extends Vue {
  // ref
  @Ref() readonly formRef;

  // prop
  // 弹窗显隐
  @Prop({ default: false }) visible!: boolean;
  // 语言id
  @Prop({ default: '' }) langId!: string;

  // 传入的编辑数据
  @Prop({
    default: () => {
      return {};
    }
  }) data!: string;

  // 语言类型新增/编辑表单实体
  formData: any = {};
  // 表单加载状态
  formLoading: boolean = false;
  // 表单验证规则
  formRules: Record<string, any> = {
    code: {
      required: true,
      message: '请填写编码',
      trigger: 'blur'
    },
    name: {
      required: true,
      message: '请填写名称',
      trigger: 'blur'
    }
  };


  /**
   * 监听弹窗显示隐藏
   * @param val
   */
  @Watch('visible')
  visibleWatcher(val) {
    if (val) {

      if (this.langId) {
        // 如果是编辑
        this.formData = this.data;
      } else {
        this.formData.priority = 1;
      }

    } else {
      // 清空表单内容
      this.formData = { priority: 1 };
      // 清理表单验证
      this.formRef.resetFields();
    }
  }

  getTitle(): string {
    return this.langId ? this.$t('lang_language_edit_type') as string : this.$t('lang_language_new_type') as string;
  }

  // 关闭弹窗
  handleCloseDialog() {
    this.$emit('success', {});
    this.updateVisible(false);
  }

  /**
   * update visible
   * @param value
   */
  updateVisible(value) {
    this.$emit('update:visible', value);
  }

  /**
   * 保存表单
   */
  saveForm() {
    // 验证表单
    Promise.all([this.formRef.validate()]).then(() => {
      let result;
      if (this.langId) {
        result = languageTypeUpdate(this.formData);
      } else {
        result = languageTypeAdd(this.formData);
      }
      result.then((res: Record<any, any>) => {
        if (res.code == '1') {
          this.$message.success(res.message);
          // 关闭弹窗
          this.handleCloseDialog();
          this.$emit('success', res.data);
        } else {
          this.$message.error(res.message);
        }
      }).finally(() => {
        // 关闭加载框
      });
    });
  }

  get dialogVisible() {
    return this.visible;
  }

  set dialogVisible(newValue) {
    this.updateVisible(false);
  }
}
